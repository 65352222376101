// Add role="none" to figures without figcaption
wpex.domReady(async function() {
    let figures = document.querySelectorAll('figure');

    if ( figures.length ) {
        figures.forEach(element => {

        // Get figcaption element
        let caption = element.querySelector('figcaption');
        if ( !caption ) {
            element.setAttribute('role', 'none');
        }
            
        });
    }
});
