wpex.domReady(async function() {
    let headingClasses = [ 'vc_custom_heading', 'vcex-heading' ],
        validHeadingTags = [],
        headingsLimit = 6;

    // Create heading tags through headingsLimit
    for (let index = 1; index < headingsLimit; index++) {
        validHeadingTags.push('H'+index);
    }

    headingClasses.forEach(className => {
        let headings = document.querySelectorAll('.' + className);

        if ( headings.length ) {
            headings.forEach(heading => {
                let tagName = heading.tagName;
                if ( !validHeadingTags.includes(tagName) ) {
                    heading.setAttribute('role', 'heading');
                }
            });
        }
    });

});