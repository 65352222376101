// Utility functions

SL9.a11y = {

    // examplePromise: (x) => {
    //     return new Promise((resolve) => {
    //       setTimeout(() => {
    //         resolve(x);
    //       }, x*1000);
    //     });
    // },

    // exampleAsync: async () => {
    //     const val = await this.examplePromise(20);
    //     return val;
    // },

    // Basic role setting without conditions
    setAriaAttr: async (selectors, value, attribute = 'role') => {
        if ( !selectors || !value ) {
            return false;
        }

        // Loop through selectors
        var newSelectors = await selectors.forEach(selector => {
            const elements = document.querySelectorAll(selector);
    
            if ( elements.length ) {
                elements.forEach(el => {
                    el.setAttribute(attribute, value);
                });
            }
        });        

    }
}